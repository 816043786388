import { Alert, Analysis, AnalysisType } from '@analyzer/client';
import {
  Box,
  Chip,
  CircularProgress,
  Alert as MuiAlert,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Tabs,
  Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useAnalysisListQuery, useAnalysisReadQuery } from '../api/analysis.js';
import { AlertComponent } from '../components/alert/view.js';
import { timeToNow } from '../components/utils.js';

enum AnalysisTypesEnum {
  Web = 'web',
  Certificate = 'certificate',
  Transparency = 'transparency',
  DNS = 'dns'
}

function AlertSeverityTitle({ alerts }: { alerts: Alert[] }) {
  const severityLevels = ['high', 'medium', 'low', 'info'] as const;
  const severityColors: Record<(typeof severityLevels)[number], string> = {
    high: '#FFB3BA',
    medium: '#FFDFBA',
    low: '#B4F0A7',
    info: '#BAE1FF'
  };

  const severityCounts = severityLevels.reduce(
    (counts, severity) => {
      const count = alerts.filter((alert) => alert.severity === severity).length;
      return { ...counts, [severity]: count };
    },
    {} as Record<(typeof severityLevels)[number], number>
  );

  return (
    <Stack direction="row" spacing={1}>
      {severityLevels.map((severity) => (
        <Chip
          size="small"
          key={severity}
          label={`${severityCounts[severity]}`}
          sx={{ backgroundColor: severityColors[severity], color: 'black' }}
        />
      ))}
    </Stack>
  );
}

function AlertsSummary({ alerts }: { alerts: Alert[] }) {
  const severityCounts = ['high', 'medium', 'low', 'info'].map((severity) => ({
    severity,
    count: alerts.filter((alert) => alert.severity === severity).length
  }));

  return (
    <>
      <Typography variant="h4">Alerts Summary</Typography>
      {severityCounts.map(({ severity, count }) => (
        <Typography key={severity} variant="body1">
          {count} {severity} severity alert(s)
        </Typography>
      ))}
    </>
  );
}

function AlertsDetails({ alerts }: { alerts: Alert[] }) {
  return (
    <>
      <Typography variant="h4">Alerts Details</Typography>
      <Box display="flex" flexDirection="column" gap={2}>
        {alerts.map((alert, index) => (
          <Box key={index}>
            <AlertComponent alert={alert} />
          </Box>
        ))}
      </Box>
    </>
  );
}

function HistoricalSnapshots({
  context,
  projectId,
  analysisType
}: {
  context: any[];
  projectId: string;
  analysisType: AnalysisType;
}) {
  return (
    <>
      <Typography variant="h4">Historical Data</Typography>
      <Typography variant="body1">The following snapshots were used as context in the analysis:</Typography>
      {context.map((snapshot, index) => (
        <Typography variant="body2" key={index}>
          <Link to={`/projects/${projectId}/snapshots/${snapshot.id}/${analysisType}`}>
            Snapshot {snapshot.id}
          </Link>
        </Typography>
      ))}
    </>
  );
}

export function AnalysisView() {
  const { projectId, analysisId } = useParams();
  if (!projectId || !analysisId) throw new Error('Missing parameter');

  const analysis = useAnalysisReadQuery(parseInt(projectId), parseInt(analysisId));

  if (analysis.isLoading) return <CircularProgress />;
  if (analysis.isError)
    return <MuiAlert severity="error">Error loading analysis: {analysis.error.message}</MuiAlert>;

  const { data } = analysis;

  return (
    <>
      <Typography variant="h3">{data.type[0].toUpperCase() + data.type.slice(1)} Analysis</Typography>
      <Typography variant="body1">Analysis created {data.createdDate.toDateString()}.</Typography>
      <Typography variant="body1">
        This analysis has produced <b>{data.alerts.length}</b> alert(s)
      </Typography>
      <Typography variant="body1">
        Project <Link to={`/projects/${data.project.id}`}>{data.project.name}</Link>
      </Typography>
      <Typography variant="body1">
        Analyzed snapshot:
        <Link to={`/projects/${projectId}/snapshots/${data.snapshot.id}/${data.type}`}>
          {data.snapshot.id}
        </Link>
      </Typography>
      {HistoricalSnapshots({ context: data.context, projectId, analysisType: data.type })}
      <AlertsSummary alerts={data.alerts} />
      <AlertsDetails alerts={data.alerts} />
    </>
  );
}

function AnalysesDetails({ type, projectId }: { type: AnalysisType; projectId: number }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const analysisList = useAnalysisListQuery(type, projectId, {
    limit: rowsPerPage,
    offset: page * rowsPerPage
  });

  if (analysisList.isLoading) return <CircularProgress />;
  const analyses = analysisList.data || [];

  analyses.sort((a, b) => b.createdDate.getTime() - a.createdDate.getTime());

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function getResource(analysis: Analysis) {
    switch (analysis.type) {
      case AnalysisTypesEnum.Certificate:
        return `Certificate analysis`;
      case AnalysisTypesEnum.Web:
        return `Web analysis`;
      case AnalysisTypesEnum.DNS:
        return `DNS analysis`;
      case AnalysisTypesEnum.Transparency:
        return `Transparency analysis`;
    }
  }

  return (
    <TableContainer>
      <Table stickyHeader size="small">
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Alerts</TableCell>
            <TableCell>Snapshot</TableCell>
            <TableCell>Resource</TableCell>
            <TableCell>Timestamp</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {analyses.map((analysis) => (
            <TableRow key={analysis.id}>
              <TableCell>
                <Link to={`/projects/${projectId}/analyses/${analysis.id}`}>{analysis.id}</Link>
              </TableCell>
              <TableCell>{AlertSeverityTitle(analysis)}</TableCell>
              <TableCell>
                <Link to={`/projects/${projectId}/snapshots/${analysis.snapshot.id}/${analysis.type}`}>
                  {analysis.snapshot.id}
                </Link>
              </TableCell>
              <TableCell>{getResource(analysis)}</TableCell>
              <TableCell>{timeToNow(analysis.createdDate)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              count={-1}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              showFirstButton={true}
              showLastButton={true}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}

export function AnalysesView() {
  const { projectId } = useParams();
  if (!projectId) throw new Error('Missing parameter: projectId');

  const [activeTab, setActiveTab] = useState(() => {
    const savedTab = localStorage.getItem(`analyses-view-active-tab-${projectId}`);
    return savedTab ? parseInt(savedTab, 10) : 0;
  });

  useEffect(() => {
    localStorage.setItem(`analyses-view-active-tab-${projectId}`, activeTab.toString());
  }, [activeTab, projectId]);

  const handleChange = (event: any, newTabValue: number) => setActiveTab(newTabValue);

  const analysisTypes = Object.values(AnalysisTypesEnum);

  return (
    <Box>
      <Tabs value={activeTab} onChange={handleChange}>
        {analysisTypes.map((type) => (
          <Tab key={type} label={type.toUpperCase()} />
        ))}
      </Tabs>
      {analysisTypes.map((type, index) => (
        <Box key={type} hidden={activeTab !== index}>
          <AnalysesDetails type={type} projectId={parseInt(projectId)} />
        </Box>
      ))}
    </Box>
  );
}
