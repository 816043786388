import { useQuery } from '@tanstack/react-query';
import { useService } from '../providers/ServiceProvider.js';

interface Result {
  start: string;
  final: string;
}

export function useTargetResolveQuery(hostname: string) {
  const client = useService();
  return useQuery<Result[], Error>({
    queryKey: ['target-resolve', hostname],
    queryFn: () => client.targetResolve(hostname),
    keepPreviousData: true,
    enabled: Boolean(hostname.trim() !== '')
  });
}
