import { Project } from '@analyzer/client';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useService } from '../providers/ServiceProvider';

export function useProjectListQuery(queryParams?: {
  name?: string;
  limit?: number;
  offset?: number;
  sortField?: 'name' | 'createdDate' | 'updatedDate';
  sortOrder?: 'ASC' | 'DESC';
}) {
  const client = useService();
  return useQuery<Project[], Error>(['project-list', queryParams], () => client.projectList(queryParams), {
    keepPreviousData: true
  });
}

export function useProjectCreateMutation() {
  const client = useService();
  const queryClient = useQueryClient();
  return useMutation(
    (data: { name: string; description: string }) => client.projectCreate(data.name, data.description),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['project-list']);
      }
    }
  );
}

export function useProjectReadQuery(projectId: number | null) {
  const client = useService();
  return useQuery<Project, Error>({
    queryKey: ['project-read', projectId],
    queryFn: () => client.projectRead(projectId!),
    enabled: !!projectId
  });
}

export function useProjectUpdateMutation() {
  const client = useService();
  const queryClient = useQueryClient();
  return useMutation(
    (data: { projectId: number; project: { name?: string; description?: string } }) =>
      client.projectUpdate(data.projectId, data.project),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['project-list']);
      }
    }
  );
}

export function useProjectImportMutation() {
  const client = useService();
  const queryClient = useQueryClient();
  return useMutation((data: string) => client.projectImport(data), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(['project-list']);
    }
  });
}

export function useProjectExportQuery() {
  const client = useService();
  return useQuery<any, Error>(['project-export'], () => client.projectExport(), {
    staleTime: Infinity,
    cacheTime: 0
  });
}

export function useProjectDestroyMutation() {
  const client = useService();
  const queryClient = useQueryClient();
  return useMutation((projectId: number) => client.projectDestroy(projectId), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(['project-list']);
    }
  });
}
