import { DomainUtils } from '@analyzer/utilities/lib/domain.js';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTargetResolveQuery } from '../../../api/target.js';

interface Project {
  name: string;
  description: string;
}

interface Test {
  name: string;
  target: string;
}

interface NewProjectModalProps {
  open: boolean;
  onClose: () => void;
  onConfirm: (project: Project, tests: Test[]) => Promise<void>;
}

interface NewProjectFormValues {
  url: string;
  name?: string;
  description?: string;
}

function SelectableTargetList({
  items,
  onSelectChange
}: {
  items: Resolve[];
  onSelectChange: (selected: Resolve[]) => void;
}) {
  const [selectedIndexes, setSelectedIndexes] = useState<number[]>([]);

  const handleToggle = (index: number) => () => {
    const currentIndex = selectedIndexes.indexOf(index);
    const newSelectedIndexes = [...selectedIndexes];
    if (currentIndex === -1) {
      newSelectedIndexes.push(index);
    } else {
      newSelectedIndexes.splice(currentIndex, 1);
    }
    setSelectedIndexes(newSelectedIndexes);
    onSelectChange(newSelectedIndexes.map((i) => items[i]));
  };

  return (
    <ul style={{ listStyleType: 'none', padding: 0 }}>
      {items.map((item, index) => {
        const isSelected = selectedIndexes.indexOf(index) !== -1;
        return (
          <li key={index}>
            <label>
              <input
                type="checkbox"
                checked={isSelected}
                onChange={handleToggle(index)}
                style={{ marginRight: '8px' }}
              />
              <a href={item.final} target="_blank" rel="noopener noreferrer">
                {item.final}
              </a>
            </label>
          </li>
        );
      })}
    </ul>
  );
}

interface Resolve {
  start: string;
  final: string;
}

export function NewProjectModal({ open, onClose, onConfirm }: NewProjectModalProps) {
  const { register, handleSubmit, getValues } = useForm<NewProjectFormValues>();
  const [selectedItems, setSelectedItems] = useState<Resolve[]>([]);
  const [hostname, setHostname] = useState<string>('');
  const { data } = useTargetResolveQuery(hostname);

  const handleSelectChange = (newSelectedItems: Resolve[]) => {
    setSelectedItems(newSelectedItems);
  };

  const handleFetch = () => {
    const url = getValues('url');
    const { hostname } = new URL(url);
    setHostname(hostname);
  };

  const handleConfirm = handleSubmit(async ({ url }) => {
    const domain = DomainUtils.getDomain(url);
    const project: Project = {
      name: domain,
      description: `Project for ${domain}`
    };

    const tests = selectedItems.map((item, index) => ({ name: `Test #${index}`, target: item.final }));
    await onConfirm(project, tests);
    onClose();
  });

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle>New Project</DialogTitle>
      <form onSubmit={handleConfirm}>
        <DialogContent>
          <Stack spacing={2}>
            <TextField label="URL" inputProps={register('url', { required: true })} />
            <Typography>Available targets</Typography>
            <SelectableTargetList items={data || []} onSelectChange={handleSelectChange} />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleFetch}>Fetch Targets</Button>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit">Create</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

interface NewProjectsModalProps {
  open: boolean;
  onClose: () => void;
  onConfirm: (projectTestPairs: { project: Project; test: Test }[]) => Promise<void>;
}

interface NewProjectsFormValues {
  urls: string;
}

const isValidUrl = (url: string): boolean => {
  try {
    void new URL(url);
    return true;
  } catch (_) {
    return false;
  }
};

export function NewProjectsModal({ open, onClose, onConfirm }: NewProjectsModalProps) {
  const { register, handleSubmit } = useForm<NewProjectsFormValues>();

  const handleConfirm = handleSubmit(async ({ urls }) => {
    const urlList = urls.split('\n').filter((url) => url.trim() !== '' && isValidUrl(url.trim()));
    const projectTestPairs = urlList.map((url) => ({
      project: {
        name: new URL(url).hostname,
        description: `Project for ${url}`,
        rules: []
      },
      test: {
        name: `Default Test`,
        target: url
      }
    }));

    await onConfirm(projectTestPairs);
    onClose();
  });

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle>Add Bulk Projects</DialogTitle>
      <form onSubmit={handleConfirm}>
        <DialogContent>
          <p>
            Enter multiple URLs, each on a new line, to create multiple projects at once. For each URL, a new
            project and an associated test will be created.
          </p>
          <Stack spacing={2}>
            <TextField label="URLs" inputProps={register('urls', { required: true })} multiline rows={14} />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit">Create</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
