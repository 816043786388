import { Alert } from '@analyzer/client';

export function AlertTitle(alert: Alert) {
  let title;
  switch (alert.type) {
    case 'transparency-log-alert':
      title = 'Transparency Log Alert';
      break;
    case 'dependency-change':
      title = 'Dependency Change';
      break;
    case 'additional-request':
      title = 'Additional Request';
      break;
    case 'certificate-change':
      title = 'Certificate Change';
      break;
    case 'content-change':
      title = 'Content Change';
      break;
    case 'dns-record-change':
      title = 'DNS Record Change';
      break;
    case 'ethereum-contract-change':
      title = 'Ethereum Contract Change';
      break;
    case 'google-safe-browsing':
      title = 'Malicious URL';
      break;
    case 'insecure-connection':
      title = 'Insecure Connection';
      break;
    case 'analyzer-blocked':
      title = 'Analyzer Blocked';
      break;
    case 'deployment-alert':
      title = 'Deployment Alert';
      break;
    case 'ethereum-provider-change':
      title = 'Ethereum Provider Change';
      break;
    case 'status-change':
      title = 'Status Change';
      break;
  }

  return title;
}
