import { useQuery } from '@tanstack/react-query';
import { useService } from '../providers/ServiceProvider.js';

export function metricsReadQuery() {
  const client = useService();
  return useQuery<any, Error>({
    queryKey: ['metrics'],
    queryFn: () => client.readMetrics()
  });
}
