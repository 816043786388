import { Backdrop, Button, ButtonGroup, CircularProgress, Grid, Typography } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import { useProjectReadQuery } from '../api/project.js';
import { useService } from '../providers/ServiceProvider.js';
import { useSnackbar } from 'notistack';

export function ProjectView() {
  const { projectId } = useParams();
  if (!projectId) {
    throw new Error('Project ID is missing from the URL');
  }

  const { enqueueSnackbar } = useSnackbar();
  const client = useService();
  const { data: project, isLoading, isError } = useProjectReadQuery(parseInt(projectId));

  if (isLoading) {
    return (
      <Backdrop open={true}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  if (isError || !project) {
    return <Typography variant="body1">Unable to load project details. Please try again later.</Typography>;
  }

  async function handleCreateSnapshot() {
    if (!project) {
      enqueueSnackbar('No project selected.', { variant: 'error' });
      return;
    }

    const [test] = await client.testList(project.id);
    if (!test) {
      enqueueSnackbar('No tests found for the project.', { variant: 'error' });
      return;
    }

    try {
      const job = await client.snapshotCreate('web', project.id, test.id);
      enqueueSnackbar(`Snapshot job (${job.id}) created successfully for test ID ${test.id}.`, {
        variant: 'success'
      });
    } catch (error) {
      enqueueSnackbar(`Failed to create snapshot job: ${error}`, { variant: 'error' });
    }
  }

  async function handleCreateAnalysis() {
    if (!project) {
      enqueueSnackbar('No project selected.', { variant: 'error' });
      return;
    }

    const [snapshot] = await client.snapshotList('web', project.id);
    if (!snapshot) {
      enqueueSnackbar('No snapshots available to analyze.', { variant: 'error' });
      return;
    }

    console.log(`Enqueueing analysis:`, 'web', project.id, snapshot.id);
    try {
      const job = await client.analysisCreate(project.id, snapshot.id);
      enqueueSnackbar(`Analysis job (${job.id}) created for snapshot ID ${snapshot.id}.`, {
        variant: 'success'
      });
    } catch (error) {
      enqueueSnackbar(`Failed to create analysis job: ${error}`, { variant: 'error' });
    }
  }

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <Typography variant="h6" gutterBottom>
            Project
          </Typography>
          <Typography variant="body2">{project.name}</Typography>
          <Typography variant="body2">{project.description}</Typography>
          <Typography variant="body2">Created on {project.createdDate.toLocaleDateString()}</Typography>
          <Typography variant="body2">Last updated {project.updatedDate.toLocaleDateString()}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="h6" gutterBottom>
            Snapshots
          </Typography>
          <Typography variant="body2">Project has 1200 snapshots</Typography>
          <Typography variant="body2">
            First snapshot created on {project.updatedDate.toLocaleDateString()}
          </Typography>
          <Typography variant="body2">
            Last snapshot created on {project.createdDate.toLocaleDateString()}
          </Typography>
          <Link to={`/projects/${project.id}/snapshots`}>View all snapshots</Link>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="h6" gutterBottom>
            Analyses
          </Typography>
          <Typography variant="body2">Project has 1200 analyses</Typography>
          <Typography variant="body2">
            First analysis created on {project.updatedDate.toLocaleDateString()}
          </Typography>
          <Typography variant="body2">
            Last analysis created on {project.createdDate.toLocaleDateString()}
          </Typography>
          <Link to={`/projects/${project.id}/analyses`}>View all analyses</Link>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="h6" gutterBottom>
            Tests
          </Typography>
          <Typography variant="body2">Project has N tests</Typography>
          <Typography variant="body2">
            First test created on {project.updatedDate.toLocaleDateString()}
          </Typography>
          <Typography variant="body2">
            Last test created on {project.createdDate.toLocaleDateString()}
          </Typography>
          <Link to={`/projects/${project.id}/tests`}>View all tests</Link>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="h6" gutterBottom>
            Assets
          </Typography>
          <Typography variant="body2">Project has N tests</Typography>
          <Typography variant="body2">
            First asset created on {project.updatedDate.toLocaleDateString()}
          </Typography>
          <Typography variant="body2">
            Last asset created on {project.createdDate.toLocaleDateString()}
          </Typography>
          <Link to={`/projects/${project.id}/assets`}>View all assets</Link>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="h6" gutterBottom>
            Roots
          </Typography>
          <Typography variant="body2">Project has N tests</Typography>
          <Typography variant="body2">
            First root created on {project.updatedDate.toLocaleDateString()}
          </Typography>
          <Typography variant="body2">
            Last root created on {project.createdDate.toLocaleDateString()}
          </Typography>
          <Link to={`/projects/${project.id}/roots`}>View all roots</Link>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            Actions
          </Typography>
          <ButtonGroup>
            <Button onClick={handleCreateSnapshot}>Queue Snapshot</Button>
            <Button onClick={handleCreateAnalysis}>Queue Analysis</Button>
          </ButtonGroup>
        </Grid>
      </Grid>
    </>
  );
}
