import { Project, Test } from '@analyzer/client';
import { Delete, Edit, Search } from '@mui/icons-material';
import {
  Backdrop,
  Button,
  CircularProgress,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  useProjectCreateMutation,
  useProjectDestroyMutation,
  useProjectListQuery,
  useProjectReadQuery,
  useProjectUpdateMutation
} from '../api/project';
import { useTestCreateMutation } from '../api/test';
import { DeleteProjectModal } from '../components/project/modals/delete';
import { EditProjectModal } from '../components/project/modals/edit';
import { NewProjectModal, NewProjectsModal } from '../components/project/modals/new';
import { handleDownload, timeToNow } from '../components/utils';
import { useModalStateHandler } from '../hooks/useModalStateHandler';
import { useService } from '../providers/ServiceProvider';

export function ProjectsView() {
  const [currentProjectId, setCurrentProjectId] = useState<number | null>(null);
  const [searchValue, setSearchValue] = useState<string>('');
  const { data: currentProject } = useProjectReadQuery(currentProjectId);
  const { data: projects = [], isLoading } = useProjectListQuery();
  const projectUpdate = useProjectUpdateMutation();
  const projectDestroy = useProjectDestroyMutation();
  const projectCreate = useProjectCreateMutation();
  const testCreate = useTestCreateMutation();
  const client = useService();

  const modals = useModalStateHandler({
    'delete-project': false,
    'new-project': false,
    'edit-project': false,
    'bulk-project': false
  });

  const handleSearchChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  }, []);

  const handleCreateClick = useCallback(() => modals.open('new-project'), [modals]);
  const handleBulkCreateClick = useCallback(() => modals.open('bulk-project'), [modals]);
  const handleImportClick = useCallback(() => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = '.json';

    input.onchange = async (event) => {
      const file = (event.target as HTMLInputElement).files?.[0];
      if (!file) return;

      try {
        const content = await file.text();
        await client.projectImport(JSON.parse(content));
      } catch (error) {
        console.error('Error importing projects:', error);
      }
    };

    input.click();
  }, [client]);

  const handleExportClick = useCallback(async () => {
    try {
      const projects = await client.projectExport();
      const date = new Date().toISOString().slice(0, 10);
      const filename = `exported-projects-${date}.json`;
      handleDownload(JSON.stringify(projects), filename);
    } catch (error) {
      console.error('Error exporting projects:', error);
    }
  }, [client]);

  const handleEditClick = useCallback(
    (id: number) => {
      setCurrentProjectId(id);
      modals.open('edit-project');
    },
    [modals]
  );

  const handleDeleteClick = useCallback(
    (id: number) => {
      setCurrentProjectId(id);
      modals.open('delete-project');
    },
    [modals]
  );

  const handleNewProject = useCallback(
    async (project: Pick<Project, 'name' | 'description'>, tests: Pick<Test, 'name' | 'target'>[]) => {
      let newProject;
      try {
        newProject = await projectCreate.mutateAsync({ ...project });
      } catch (error) {
        console.error('Error creating new project:', error);
        return;
      }

      for (const test of tests) {
        try {
          await testCreate.mutateAsync({
            projectId: newProject.id,
            test: { name: test.name, target: test.target }
          });
        } catch (error) {
          console.error('Error creating new tests:', error);
          continue;
        }
      }
    },
    [projectCreate, testCreate]
  );

  const handleEditProject = useCallback(
    (project: Partial<Project>) => {
      if (currentProjectId) {
        projectUpdate.mutate({ projectId: currentProjectId, project });
      }
    },
    [currentProjectId, projectUpdate]
  );

  const handleDeleteProject = useCallback(() => {
    if (currentProjectId) {
      projectDestroy.mutate(currentProjectId);
    }
  }, [currentProjectId, projectDestroy]);

  const handleBulkCreate = useCallback(
    async (
      projectTestPairs: Array<{
        project: Pick<Project, 'name' | 'description'>;
        test: Pick<Test, 'name' | 'target'>;
      }>
    ) => {
      for (const { project, test } of projectTestPairs) {
        try {
          const newProject = await projectCreate.mutateAsync({ ...project });
          await testCreate.mutateAsync({ projectId: newProject.id, test });
        } catch (error) {
          console.error(`Error creating bulk reports: ${error}`);
        }
      }
    },
    [projectCreate, testCreate]
  );

  // Sort by date and filter by name if there is a search value.
  const filteredProjects = projects
    .sort((a, b) => b.createdDate.getTime() - a.createdDate.getTime())
    .filter((project) => project.name.toLowerCase().includes(searchValue.toLowerCase()));

  if (isLoading) {
    return (
      <Backdrop open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  return (
    <>
      <NewProjectModal
        open={modals.state['new-project']}
        onClose={() => modals.close('new-project')}
        onConfirm={handleNewProject}
      />
      {currentProject && (
        <EditProjectModal
          project={currentProject}
          open={modals.state['edit-project']}
          onClose={() => modals.close('edit-project')}
          onConfirm={handleEditProject}
        />
      )}
      {currentProject && (
        <DeleteProjectModal
          project={currentProject}
          open={modals.state['delete-project']}
          onClose={() => modals.close('delete-project')}
          onConfirm={handleDeleteProject}
        />
      )}
      <NewProjectsModal
        open={modals.state['bulk-project']}
        onClose={() => modals.close('bulk-project')}
        onConfirm={handleBulkCreate}
      />

      <Stack spacing={4}>
        <Stack spacing={2} direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="h5">Tracking {projects.length} projects</Typography>
          <Stack spacing={2} direction="row">
            <TextField
              value={searchValue}
              onChange={handleSearchChange}
              placeholder="Search Project"
              variant="outlined"
              size="small"
              sx={{ minWidth: '300px' }}
              InputProps={{ endAdornment: <Search /> }}
            />
            <Button variant="contained" color="primary" onClick={handleCreateClick}>
              Create
            </Button>
            <Button variant="contained" color="primary" onClick={handleBulkCreateClick}>
              Bulk Create
            </Button>
            <Button variant="contained" color="secondary" onClick={handleImportClick}>
              Import
            </Button>
            <Button variant="contained" color="success" onClick={handleExportClick}>
              Export
            </Button>
          </Stack>
        </Stack>
        <TableContainer>
          <Table stickyHeader size="small">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Actions</TableCell>
                <TableCell>Roots</TableCell>
                <TableCell>Tests</TableCell>
                <TableCell>Assets</TableCell>
                <TableCell>Domains</TableCell>
                <TableCell>Filters</TableCell>
                <TableCell>Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredProjects.map(
                ({ id, name, description, roots, tests, createdDate, assets, domains, rules }) => (
                  <TableRow key={id}>
                    <TableCell>
                      <Link to={`/projects/${id}`}>{name}</Link>
                    </TableCell>
                    <TableCell>{description.substring(0, 60)}...</TableCell>
                    <TableCell>
                      <IconButton onClick={() => handleEditClick(id)}>
                        <Edit />
                      </IconButton>
                      <IconButton onClick={() => handleDeleteClick(id)}>
                        <Delete />
                      </IconButton>
                    </TableCell>
                    <TableCell>{roots.length}</TableCell>
                    <TableCell>{tests.length}</TableCell>
                    <TableCell>{assets.length}</TableCell>
                    <TableCell>{domains.length}</TableCell>
                    <TableCell>{rules.length}</TableCell>
                    <TableCell>{timeToNow(createdDate)}</TableCell>
                  </TableRow>
                )
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
    </>
  );
}
