import { Alert, AlertColor, Button, Snackbar, Stack, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { useService } from '../providers/ServiceProvider.js';

function EmitAnalysisComponent() {
  const [id, setId] = useState('');
  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    message: string;
    severity: AlertColor;
  }>({
    open: false,
    message: '',
    severity: 'info'
  });

  const client = useService();

  const handleEmitAnalysis = async () => {
    if (!id || isNaN(Number(id))) {
      setSnackbar({ open: true, message: 'Please enter a valid numeric ID', severity: 'error' });
      return;
    }

    try {
      const response = await client.devEmitAnalysis(parseInt(id));
      if (!response) {
        throw new Error('Network response was not ok');
      }
      setSnackbar({ open: true, message: 'Analysis emitted successfully', severity: 'success' });
    } catch (error) {
      console.error('Error emitting analysis:', error);
      setSnackbar({ open: true, message: 'Failed to emit analysis', severity: 'error' });
    }
  };

  const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') return;
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <>
      <Stack direction="row" spacing={2} alignItems="center">
        <TextField
          value={id}
          onChange={(e) => setId(e.target.value)}
          label="Analysis ID"
          variant="outlined"
          size="small"
          type="number"
          inputProps={{ min: 0 }}
        />
        <Button variant="contained" onClick={handleEmitAnalysis}>
          Emit Analysis
        </Button>
      </Stack>
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
}

export function DevelopmentView() {
  return (
    <Stack spacing={2}>
      <Typography variant="h5">Development Tools</Typography>
      <EmitAnalysisComponent />
    </Stack>
  );
}
